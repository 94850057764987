










































import Vue from 'vue';
import { Getter } from 'vuex-class';
import { Component, Inject, Watch } from 'vue-property-decorator';

import { fillRange } from '@/helpers/array';
import { FontLoader } from '@/helpers/fonts';
import { FontFace } from '../types';
import { FsCanvas, TextSettings, CANVAS_EVENTS } from '@filepicker/filestack-canvas';
import ColorPicker from '@/components/ColorPicker.vue';

@Component({
  components: {
    ColorPicker,
  },
})
class TextTool extends Vue {
  @Inject('canvas')
  private canvas: FsCanvas;

  @Getter('config')
  private config: any;

  private evHandler: any;

  private get sizes(): number[] {
    return fillRange(4, 200, 2);
  }

  private get fonts(): FontFace {
    return this.config('editor.text.fonts');
  }

  private get defaultFont() {
    const conf = this.config('editor.text.fonts');
    return conf.find((el: any) => el.default);
  }

  private get isAddMode() {
    return this.canvas.text.isAddEnabled;
  }

  private get defaultSettings(): TextSettings {
    return {
      ...this.settingsModel,
      ...this.config('editor.text.defaults'),
      font: this.defaultFont.family,
    };
  }

  private settingsModel: TextSettings = {
    size: null,
    font: null,
    bold: false,
    underline: false,
    italic: false,
    backgroundColor: null,
    color: null,
    align: 'left',
  };

  private mounted() {
    Vue.set(this, 'settingsModel', this.defaultSettings);

    this.canvas.text.setSettings(this.settingsModel);
    this.canvas.text.setInitialText(this.settingsModel.initialText);

    delete this.settingsModel.initialText;

    this.canvas.on(CANVAS_EVENTS.TEXT_EDIT_START, this.onTextStartEdit);
    this.canvas.on(CANVAS_EVENTS.TEXT_EDIT_FINISH, this.onTextEditEnd);
    this.canvas.on(CANVAS_EVENTS.TEXT_SELECTED, this.onTextSelected);

    this.evHandler = this.handleKeyboard.bind(this);

    FontLoader(this.config('editor.text.fonts')).then(() => {
      this.canvas.text.enable();
      this.toggleAdd();
    });

    document.addEventListener('keydown', this.evHandler);
  }

  private destroyed() {
    this.canvas.off(CANVAS_EVENTS.TEXT_EDIT_START);
    this.canvas.off(CANVAS_EVENTS.TEXT_EDIT_FINISH);
    this.canvas.off(CANVAS_EVENTS.TEXT_SELECTED);

    this.canvas.text.disable();
    document.removeEventListener('keydown', this.evHandler);
  }

  private handleKeyboard(e: KeyboardEvent) {
    // do not catch other keys
    // backspace and delete -> remove selected text
    if ([8, 46].indexOf(e.keyCode) === -1) {
      return;
    }

    // @ts-ignore
    if (e && e.target && e.target.tagName === 'INPUT') {
      return;
    }

    if (this.canvas.text.isEditing) {
      return false;
    }

    e.stopPropagation();
    e.preventDefault();

    this.canvas.text.removeLayer(this.canvas.text.selected);
  }

  @Watch('settingsModel', { deep: true })
  private onSettingsUpdate() {
    this.canvas.text.setSettings(this.settingsModel);
    this.canvas.saveState('textSettingsUpdate');
  }

  private toggleAdd() {
    if (!this.canvas.text.isAddEnabled) {
      return this.canvas.text.enableAddMode();
    }

    this.canvas.text.disableAddMode();
  }

  private isEnabled<T extends keyof TextSettings>(key: T, value: TextSettings[T]) {
    return this.settingsModel[key] === value;
  }

  private setFormat<T extends keyof TextSettings>(key: T, value: TextSettings[T]) {
    if (typeof this.settingsModel[key] === 'undefined') {
      return;
    }

    Vue.set(this.settingsModel, key, value);
  }

  private onTextStartEdit() {
    // disable add mode after start editing new text
    this.canvas.text.disableAddMode();
  }

  private onTextSelected(settings: TextSettings) {
    Vue.set(this, 'settingsModel', settings);
  }

  private onTextEditEnd() {
    this.canvas.saveState('textEditEnd');
  }
}

export default TextTool;
