







import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
class FsRange extends Vue {

  private get step(): number {
    if (!this.min || !this.max) {
      return 0.01;
    }

    return (this.max - this.min) / 100;
  }

  @Prop()
  private value: number;

  @Prop()
  private min: number;

  @Prop()
  private max: number;

  @Prop()
  private label: string;

  @Prop()
  private disabled: boolean;


  get percentage() {
    if (!this.min || !this.max) {
      return this.valueModel;
    }

    const range = this.max - this.min;

    return `${Math.round(((this.valueModel - this.min) * 100) / range)} %`;
  }

  get valueModel(): number {
    return this.value || 0;
  }

  set valueModel(val) {
    this.$emit('input', +val);
  }
}

export default FsRange;
