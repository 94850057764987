






import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import vueCustomScrollbar from 'vue-custom-scrollbar';

@Component({
  components: { vueCustomScrollbar },
})
class FsSlider extends Vue {
  private settings: any = {
    suppressScrollY: true,
    suppressScrollX: false,
    wheelPropagation: true,
  };
}

export default FsSlider;
