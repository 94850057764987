

















import Vue from 'vue';
import { Component, Watch, Prop } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';
import { ToastMessage, ToastMessageType, ToastAction } from '../types';

const DEFAULT_MSG_DURATION = 4000;

@Component
class Toast extends Vue {

  private toDisplay: ToastMessage = null;

  @Getter('toast/messages')
  private messages: ToastMessage[];

  @Getter('toast/duration')
  private duration: number;

  @Mutation('toast/remove')
  private removeMsg: (msg: ToastMessage) => void;

  private isVisible: boolean = false;

  private getClass(msg: ToastMessage) {
    let msgCls = 'fs-base';

    if (msg && msg.type) {
      msgCls = `fs-${msg.type}`;
    }

    return { [msgCls]: true, 'fs-visible': this.isVisible, 'fs-hidden': !this.isVisible };
  }

  @Watch('messages')
  private onMessage() {
    this.displayStart();
  }

  private displayStart() {
    if (!this.messages || !this.messages[0] || this.isVisible) {
      return;
    }

    this.toDisplay = this.messages[0];
    this.isVisible = true;

    if (!this.toDisplay.actions || !this.toDisplay.actions.length) {
      this.close();
    }
  }

  private close(withoutTimeout: boolean = false) {
    setTimeout(() => {
      this.isVisible = false;

      // timeout for animation
      setTimeout(() => {
        this.removeMsg(this.toDisplay);
        this.toDisplay = null;

        // check for next messages
        this.displayStart();
      }, 300);

    }, withoutTimeout ? 0 : this.toDisplay.duration || this.duration || DEFAULT_MSG_DURATION);
  }

  private onActionClick(action: ToastAction) {
    this.$emit(action.title);

    if (action.callback) {
      action.callback();
    }

    if (this.isVisible) {
      this.close(true);
    }
  }
}

export default Toast;
