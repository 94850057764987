






import { Adjustments } from '@filepicker/filestack-canvas';
import { Component } from 'vue-property-decorator';
import { FilterMixin } from './FilterMixin';

@Component
class Saturation extends FilterMixin {

  protected value = { saturation: 0 };

  protected filter = new Adjustments.Saturation();
}

export default Saturation;
