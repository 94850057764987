import Vue from 'vue';
import { Component, Inject, Watch } from 'vue-property-decorator';
import { FsCanvas, FilterPayload, CANVAS_EVENTS } from '@filepicker/filestack-canvas';

// You can declare a mixin as the same style as components.
@Component
export class FilterMixin extends Vue {
  protected value: any;

  protected filter: any;

  private defaultValue: any;

  private doNotRefresh: boolean;

  protected get id() {
    return this.$options.name;
  }

  @Inject('canvas')
  private canvas: FsCanvas;

  protected mounted() {
    this.canvas.on(CANVAS_EVENTS.UNDO, this.onUndo);

    this.defaultValue = JSON.parse(JSON.stringify(this.value));
    this.updateValue();
  }

  protected destroy() {
    this.canvas.off(CANVAS_EVENTS.UNDO, this.onUndo);
  }

  protected getFilter(): FilterPayload {
    return this.canvas.filters.getItemsById(this.id);
  }

  protected updateValue() {
    this.doNotRefresh = true;
    const item = this.getFilter();

    if (item) {
      this.value = item.value;
    } else {
      this.value = JSON.parse(JSON.stringify(this.defaultValue));
    }

    this.$nextTick(() => {
      this.doNotRefresh = false;
    });
  }

  protected resetFilter(): void {
    return this.canvas.filters.deleteById(this.id);
  }

  @Watch('value', { deep: true })
  protected onValueChange() {
    if (this.doNotRefresh) {
      return;
    }

    this.filter.setValue(JSON.parse(JSON.stringify(this.value)));

    this.canvas.filters.putItem(this.filter.getPayload());
    this.canvas.filters.render().then(() => {
      this.canvas.saveState();
    });
  }

  private onUndo() {
    this.updateValue();
  }
}
