





















import Vue from 'vue';
import { Route } from 'vue-router';
import { Component, Ref, Inject, Watch } from 'vue-property-decorator';

import Canvas from './Canvas.vue';
import Toast from './Toast.vue';
import { FsCanvas } from '@filepicker/filestack-canvas';
import { Getter } from 'vuex-class';

@Component({
  components: { Canvas, Toast },
})
class Main extends Vue {

  @Inject('canvas')
  public canvas: FsCanvas;

  private topMenuHeight: number = 40;
  private bottomMenuHeight: number = 50;

  @Getter('source')
  private source: any;

  private menuSpace: number = 0;

  @Ref('container')
  private containerRef: HTMLDivElement;

  @Getter('isBlocked')
  private isAppBlocked: boolean;

  @Watch('$route')
  private onRouteChange(to: Route) {
    const current = to.matched.find((r) => r.name === to.name);

    this.menuSpace = 0;

    if (current.components.topMenu) {
      this.menuSpace += this.topMenuHeight;
    }

    if (current.components.bottomMenu || current.components.default) {
      this.menuSpace += this.bottomMenuHeight;
    }

    this.onResize();
  }

  private mounted() {
    this.onResize();
  }

  private onResize() {
    this.canvas.view.setSize({
      width: this.containerRef.offsetWidth,
      height: this.containerRef.offsetHeight - this.menuSpace,
    });
  }
}

export default Main;
