






import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
class ViewSlot extends Vue {

}

export default ViewSlot;
