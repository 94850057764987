






import { Adjustments } from '@filepicker/filestack-canvas';
import { Component } from 'vue-property-decorator';
import { FilterMixin } from './FilterMixin';

@Component
class Noise extends FilterMixin {

  protected filter = new Adjustments.Noise();

  protected value = { noise: 0 };
}

export default Noise;
