






import { Adjustments } from '@filepicker/filestack-canvas';
import { Component } from 'vue-property-decorator';
import { FilterMixin } from './FilterMixin';

@Component
class Hue extends FilterMixin {

  protected value = { rotation: 0 };

  protected filter = new Adjustments.Hue();

}

export default Hue;
