










































import Vue from 'vue';
import { Getter } from 'vuex-class';
import { Component, Inject, Watch } from 'vue-property-decorator';

import { FsCanvas, TextSettings, BorderSettings, CANVAS_EVENTS } from '@filepicker/filestack-canvas';
import ColorPicker from '@/components/ColorPicker.vue';
import { Chrome } from 'vue-color';
import set from 'lodash.set';

@Component({
  components: {
    ColorPicker,
    color: Chrome,
  },
})
class Border extends Vue {
  @Inject('canvas')
  private canvas: FsCanvas;

  @Getter('config')
  private config: any;

  // @todo do it better
  private undoHandler: any;

  private settingsModel: BorderSettings = {
    color: '#000',
    width: 20,
    opacity: 50,
  };

  private mounted() {
    this.undoHandler = () => {
      const settings = this.canvas.border.readConfig();

      if (settings) {
        this.settingsModel = { ...settings };
      } else {
        this.settingsModel = { ...this.config('editor.border.defaults') };
      }
    };

    this.canvas.on(CANVAS_EVENTS.UNDO, this.undoHandler);
  }

  private destroyed() {
    this.canvas.off(CANVAS_EVENTS.UNDO, this.undoHandler);
  }

  private created() {
    const settings = this.canvas.border.readConfig();

    if (settings) {
      this.settingsModel = { ...settings };
    } else {
      this.settingsModel = { ...this.config('editor.border.defaults') };
    }
  }

  private numbersSequence(max: number, nth: number) {
    const numbers = Array.from({ length: max }, (v: number, k: number) => k + 1);
    return numbers.filter((e: number, i: number) => i % nth === nth - 1);
  }

  private onChange() {
    const settings = Object.assign({}, this.settingsModel);
    settings.width = Number(settings.width);
    settings.opacity = Number(settings.opacity);
    this.canvas.border.enable(settings);
    this.canvas.saveState('borderSettingsUpdate');
  }

  private remove() {
    this.canvas.border.remove();
    this.canvas.saveState('borderSettingsUpdate');
  }
}

export default Border;
