
























import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import { routes } from '@/router';
import { getByName } from '@/helpers/router';
import { isActive } from '../helpers/router';

@Component
class Nav extends Vue {
  @Getter('config')
  private config: any;

  @Getter('image/isLocked')
  private isImageLocked: boolean;

  @Getter('isBlocked')
  private isAppBlocked: boolean;

  get isSplitted(): boolean {
    return (this.$route.meta && this.$route.meta.menuFrom) || this.$route.meta.backEnabled;
  }

  get menuItems(): any {
    const routeData = getByName(this.$route.name, routes);
    if (!routeData) {
      return [];
    }

    let toReturn: any[] = [];

    if (routeData.meta && routeData.meta.menuFrom) {
      toReturn = getByName(routeData.meta.menuFrom, routes).children;
    } else {
      toReturn = getByName('main', routes).children;
    }

    return toReturn.filter((el: any) => !!el.meta);
  }

  private isVisible(meta: any) {
    const visible = isActive(meta, this.config);

    if (visible === false) {
      return false;
    }

    return true;
  }
}

export default Nav;
