






import { Component } from 'vue-property-decorator';
import { Adjustments } from '@filepicker/filestack-canvas';

import { FilterMixin } from './FilterMixin';

@Component
class Blur extends FilterMixin {
  protected filter = new Adjustments.Blur();
  protected value = { blur: 0 };
}

export default Blur;

