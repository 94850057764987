






import { Component } from 'vue-property-decorator';
import { Adjustments } from '@filepicker/filestack-canvas';

import { FilterMixin } from './FilterMixin';

@Component
class Brightness extends FilterMixin {

  protected filter = new Adjustments.Brightness();

  protected value = { brightness: 0 };
}

export default Brightness;
