import Vue from 'vue';
import { ImageState } from './../../types';

const initialState: ImageState = {
  background: null,
  isZoomLocked: false,
  isReadonly: false,
  isLoading: true,
  history: [],
};

export default {
  namespaced: true,

  state: Object.assign({}, initialState),
  getters: {
    isReadonly: (st: ImageState) => st.isReadonly,
    isLoading: (st: ImageState) => st.isLoading,
    isLocked: (st: ImageState) =>  st.isReadonly,
    background: (st: ImageState) => st.background,
    isZoomLocked: (st: ImageState) => st.isZoomLocked,
    hasHistory: (st: ImageState)  => st.history && st.history.length > 1, // greater than 1 because 1 state is always base image
    historyState: (st: ImageState) => st.history,
    lastState: (state: ImageState) => {
      const history = [...state.history];
      if (!history || history.length === 0) {
        return null;
      }

      return (history.pop()).state;
    },
  },
  actions: {
    popLastHistoryState({ state, commit }: { state: ImageState, commit: any }): any {
      if (!state.history || state.history.length === 0) {
        return null;
      }

      const st = [...state.history];
      st.reverse();

      if (state.history.length !== 1) {
        commit('popHistoryState');
      }

      return state.history[0];
    },
  },
  mutations: {
    setReadonly: (st: ImageState, state: boolean) => {
      Vue.set(st, 'isReadonly', state);
    },
    setLoading: (st: ImageState, state: boolean) => {
      Vue.set(st, 'isLoading', state);
    },
    popHistoryState: (st: ImageState, idx: number) => {
      if (!st.history) {
        st.history = [];
      }

      return st.history.pop();
    },
    saveHistoryState: (st: ImageState, data: any) => {
      if (!st.history) {
        Vue.set(st, 'history', [data]);
        return;
      }

      const last = st.history[st.history.length - 1];

      if (last === data) {
        return;
      }

      st.history.push(data);
    },
    setZoomLock: (st: ImageState, locked: boolean) => {
      Vue.set(st, 'isZoomLocked', locked);
    },
    setUrl: (st: ImageState, url: string) => {
      Vue.set(st, 'url', url);
    },
    setOutputWidth: (st: ImageState, size: number) => {
      Vue.set(st, 'outputWidth', size);
    },
    setOutputHeight: (st: ImageState, size: number) => {
      Vue.set(st, 'outputHeight', size);
    },
    setBackground: (st: ImageState, color: string) => {
      Vue.set(st, 'background', color);
    },
  },
};
