









import Vue from 'vue';
import { Component, Provide, Prop } from 'vue-property-decorator';
import { Mutation, Getter } from 'vuex-class';

import { ToastMessage, ToastMessageType, ViewMode } from '@/types';
import { FsCanvas, CANVAS_EVENTS } from '@filepicker/filestack-canvas';

@Component({
  name: 'FsTransformsUi',
})
class App extends Vue {

  @Provide('canvas')
  private canvas: FsCanvas;

  @Getter('isBlocked')
  private isAppBlocked: boolean;

  @Mutation('toast/show')
  private showToast: (msg: ToastMessage) => void;

  @Mutation('image/setReadonly')
  private setReadonly: (msg: boolean) => void;

  @Getter('viewMode')
  private viewMode: ViewMode;

  private get isInline() {
    return this.viewMode === ViewMode.INLINE;
  }

  private beforeCreate() {
    // @ts-ignore
    this.canvas = this.$root.canvasInstance;
  }

  private mounted() {
    this.$emit('canvas:ready', this.canvas);
    this.canvas.on(CANVAS_EVENTS.READONLY, () => {
      this.setReadonly(true) ;

      this.showToast({
        title: 'readonly.title',
        message: 'readonly.message',
        type: ToastMessageType.INFO,
      });
    });
  }

  private destroy() {
    this.canvas.off(CANVAS_EVENTS.READONLY);
  }
}

export default App;
