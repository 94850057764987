








import Vue from 'vue';
import { Getter, Mutation } from 'vuex-class';
import { Component, Watch, Inject, Ref } from 'vue-property-decorator';
import { FsCanvas, CANVAS_EVENTS } from '@filepicker/filestack-canvas';

@Component
class Canvas extends Vue {

  private get canvasConfig() {
    return this.config('canvasConfig');
  }

  private isLoading: boolean = true;

  @Inject('canvas')
  private canvas: FsCanvas;

  @Getter('config')
  private config: any;

  @Ref('canvas-container')
  private canvasRef: HTMLDivElement;

  @Ref('canvas-style-loader')
  private styleLoaderRef: HTMLDivElement;

  @Getter('source')
  private source: string;

  @Mutation('image/saveHistoryState')
  private saveHistoryState: (state: any) => any;

  @Mutation('image/setLoading')
  private setLoding: (state: boolean) => any;

  @Watch('source')
  private onSourceChange(source: string): void {
    if (!source || source.length === 0) {
      return;
    }

    this.canvas.renderImage(source).then(() => {
      this.canvas.saveState('sourceChange');
    });
  }

  private mounted() {
    this.canvas.setElement('fs-transform-canvas', this.config('canvasConfig'));
    const elementStyles = window.getComputedStyle(this.styleLoaderRef);

    this.canvas.mask.setStyle({
      cornerColor: elementStyles.getPropertyValue('background-color'),
      cornerStrokeColor: elementStyles.getPropertyValue('border-top-color'),
      maskColor: elementStyles.getPropertyValue('border-bottom-color'),
    });

    this.canvas.on(CANVAS_EVENTS.MODIFIED, (data: any) => {
      this.saveHistoryState(data);
    });

    this.canvas.on(CANVAS_EVENTS.LOADING, (state: boolean) => {
      this.isLoading = state;
      this.setLoding(state);
    });

    this.onSourceChange(this.source);
  }

  private destroyed() {
    this.canvas.off(CANVAS_EVENTS.LOADING);
    this.canvas.off(CANVAS_EVENTS.MODIFIED);
  }
}

export default Canvas;
