import { MaskPayload } from '@filepicker/filestack-canvas';
import Vue from 'vue';
import { CropState, AppliedCrop } from '@/types';

const initialState: CropState = {
  isApplied: false,
  appliedCrop: {
    top: null,
    left: null,
    width: null,
    height: null,
    radius: null,
  },
  selectedRatio: null,
};

export default {
  namespaced: true,
  state: Object.assign({}, initialState),
  getters: {
    isApplied: (st: CropState) => st.isApplied,
    selectedRatio: (st: CropState) => st.selectedRatio,
    appliedCropArea: (st: CropState) => st.appliedCrop,
  },
  mutations: {
    resetCropArea: (st: CropState) => {
      Vue.set(st, 'appliedCrop', { top: null, left: null, width: null, height: null, radius: null });
      Vue.set(st, 'isApplied', false);
    },
    setCropArea: (st: CropState, { top, left, width, height, radius }: AppliedCrop) => {
      Vue.set(st, 'appliedCrop', { top, left, width, height, radius });
      Vue.set(st, 'isApplied', true);
    },
    setSelectedRatio(st: CropState, selected: MaskPayload) {
      Vue.set(st, 'selectedRatio', selected);
    },
  },
};
