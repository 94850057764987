















import Vue from 'vue';
import { Component, Inject } from 'vue-property-decorator';

import Zoom from './Zoom.vue';
import { Getter, Action } from 'vuex-class';
import { FsCanvas, OutputFormat } from '@filepicker/filestack-canvas';

@Component({ components: { Zoom } })
class Header extends Vue {

  @Getter('config')
  private config: any;

  @Inject('canvas')
  private canvas: FsCanvas;

  @Getter('isBlocked')
  private isAppBlocked: boolean;

  @Getter('image/hasHistory')
  private hasHistory: boolean;

  @Getter('image/historyState')
  private historyState: any[];

  @Getter('image/lastState')
  private lastState: any;

  @Action('image/popLastHistoryState')
  private popHistoryState: () => any;

  private onExport() {

    if (this.canvas.transform.isCropMode) {
      this.canvas.mask.destroy();
    }

    this.canvas.export(this.config('output') as OutputFormat).then(() => {
      this.$root.$destroy();
    });
  }

  private onUndo() {
    this.popHistoryState().then(() => {
      if (!this.lastState) {
        return;
      }

      this.canvas.restoreState(this.lastState);
    });
  }

  private onClose() {
    this.$root.$emit('destroy');
    // this.$root.$destroy();
    // this.$root.$el.parentNode.removeChild(this.$root.$el);
  }
}

export default Header;
