export const fillRange = (start: number, end: number, step: number) => {
  let index = -1;

  let length = Math.max(Math.ceil((end - start) / (step || 1)), 0);

  const result = new Array(length);

  while (length--) {
    result[++index] = start;
    start += step;
  }

  return result;
};
