// Main components
import ViewSlot from '@/views/ViewSlot.vue';
import Nav from '@/views/Nav.vue';
import FiltersMenu from '@/views/FiltersMenu.vue';
import Main from '@/views/Main.vue';
import Header from '@/views/Header.vue';

// Menu Items
import TransformsTop from '@/views/transforms/Top.vue';
import TransformsBottom from '@/views/transforms/Bottom.vue';
import TextTool from '@/views/TextTool.vue';
import Border from '@/views/Border.vue';

import AdBlur from '@/views/adjustments/Blur.vue';
import AdBrightness from '@/views/adjustments/Brightness.vue';
import AdContrast from '@/views/adjustments/Contrast.vue';
import AdGamma from '@/views/adjustments/Gamma.vue';
import AdHue from '@/views/adjustments/Hue.vue';
import AdNoise from '@/views/adjustments/Noise.vue';
import AdPixelate from '@/views/adjustments/Pixelate.vue';
import AdSaturation from '@/views/adjustments/Saturation.vue';

export const routes = [
  {
    path: '/',
    name: 'main',
    components: {
      default: Main,
    },
    children: [
      {
        path: '/',
        name: 'default',
        components: {
          topBar: Header,
          bottomBar: Nav,
        },
      },
      {
        path: '/transforms',
        name: 'transforms',
        meta: {
          enabledEntry: 'editor.transforms.enabled',
          title: 'transforms',
          icon: 'crop',
          backEnabled: true,
        },
        components: {
          topBar: Header,
          topMenu: TransformsTop,
          bottomMenu: TransformsBottom,
          bottomBar: Nav,
        },
      },
      {
        path: '/filters',
        name: 'filters',
        meta: {
          enabledEntry: 'editor.filters.enabled',
          title: 'filters',
          icon: 'filter',
          menuFrom: 'filters',
        },
        components: {
          topBar: Header,
          bottomBar: FiltersMenu,
        },
      },
      {
        path: '/adjustments',
        name: 'adjustments',
        meta: {
          enabledEntry: 'editor.adjustments.enabled',
          title: 'adjustments',
          icon: 'tune',
          menuFrom: 'adjustments',
        },
        components: {
          topBar: Header,
          bottomMenu: ViewSlot,
          bottomBar: Nav,
        },
        redirect: { name: 'blur' },
        children: [
          {
            path: '/blur',
            name: 'blur',
            meta: {
              enabledEntry: {
                path: 'editor.adjustments.enabled',
                item: 'blur',
              },
              title: 'blur',
              icon: 'blur_on',
              menuFrom: 'adjustments',
            },
            component: AdBlur,
          },
          {
            path: '/brightness',
            name: 'brightness',
            meta: {
              enabledEntry: {
                path: 'editor.adjustments.enabled',
                item: 'brightness',
              },
              title: 'brightness',
              icon: 'settings_brightness',
              menuFrom: 'adjustments',
            },
            component: AdBrightness,
          },
          {
            path: '/contrast',
            name: 'contrast',
            meta: {
              enabledEntry: {
                path: 'editor.adjustments.enabled',
                item: 'contrast',
              },
              title: 'contrast',
              icon: '',
              customClass: 'fs-filter-contrast',
              menuFrom: 'adjustments',
            },
            component: AdContrast,
          },
          {
            path: '/gamma',
            name: 'gamma',
            meta: {
              enabledEntry: {
                path: 'editor.adjustments.enabled',
                item: 'gamma',
              },
              title: 'gamma',
              icon: 'color_lens',
              menuFrom: 'adjustments',
            },
            component: AdGamma,
          },
          {
            path: '/hue',
            name: 'hue',
            meta: {
              enabledEntry: {
                path: 'editor.adjustments.enabled',
                item: 'hue',
              },
              title: 'hue',
              icon: '',
              customClass: 'fs-filter-hue',
              menuFrom: 'adjustments',
            },
            component: AdHue,
          },
          {
            path: '/noise',
            name: 'noise',
            meta: {
              enabledEntry: {
                path: 'editor.adjustments.enabled',
                item: 'noise',
              },
              title: 'noise',
              icon: '',
              customClass: 'fs-filter-noise',
              menuFrom: 'adjustments',
            },
            component: AdNoise,
          },
          {
            path: '/pixelate',
            name: 'pixelate',
            meta: {
              enabledEntry: {
                path: 'editor.adjustments.enabled',
                item: 'pixelate',
              },
              title: 'pixelate',
              icon: 'apps',
              menuFrom: 'adjustments',
            },
            component: AdPixelate,
          },
          {
            path: '/saturation',
            name: 'saturation',
            meta: {
              enabledEntry: {
                path: 'editor.adjustments.enabled',
                item: 'saturation',
              },
              title: 'saturation',
              icon: '',
              customClass: 'fs-filter-saturation',
              menuFrom: 'adjustments',
            },
            component: AdSaturation,
          },
        ],
      },
      {
        path: '/text-tool',
        name: 'text-tool',
        meta: {
          enabledEntry: 'editor.text.enabled',
          title: 'text_tool',
          icon: 'text_fields',
          backEnabled: true,
        },
        components: {
          bottomMenu: TextTool,
          topBar: Header,
          bottomBar: Nav,
        },
      },
      {
        path: '/border',
        name: 'border',
        meta: {
          enabledEntry: 'editor.border.enabled',
          title: 'border',
          icon: '',
          customIcon: '<svg width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="1.5" y="1.5" width="20" height="15" rx="3.5" stroke="black" stroke-width="3" /></svg>',
          iconClass: 'nav-icon-border',
          backEnabled: true,
        },
        components: {
          bottomMenu: Border,
          topBar: Header,
          bottomBar: Nav,
        },
      },
    ],
  },
  {
    path: '*',
    components: {
      default: Main,
    },
  },
];
